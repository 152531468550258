<template>
  <div>
    <div style="display: flex; flex-direction: column">
      <div>
        <load-item-selector></load-item-selector>
      </div>
      <div style="flex: 1">
        <vehicle-list></vehicle-list>
      </div>
    </div>
  </div>
</template>
<script>
import LoadItemSelector from "@/components/current-loads/LoadItemSelector.vue";
import VehicleList from "@/components/current-loads/VehicleList.vue";

export default {
  components: {
    LoadItemSelector,
    VehicleList
  },
  mounted() {
    this.$store.dispatch("goods/loadGoodsShortInfo");
    this.$store.dispatch("goodsGroup/loadGoodsGroupsShortInfo");
    this.$store.dispatch("currentLoads/loadData");
  }
};
</script>
