<style scoped>
.gray-container {
  background-color: #eceeef;
  border-radius: 5px;
}
</style>

<template>
  <div>
    <b-container fluid class="gray-container">
      <b-row class="mb-2 p-3">
        <b-col>
          <b-form-checkbox
            :checked="filter.showLessThanMinimal"
            @change="onSwitchFilterChanged($event, 'showLessThanMinimal')"
            switch
          >
            {{ $t("current-loads.less-than-minimal-switch") }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox
            :checked="filter.showLessThanRecommended"
            @change="onSwitchFilterChanged($event, 'showLessThanRecommended')"
            switch
          >
            {{ $t("current-loads.less-than-recommended-switch") }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox
            :checked="filter.showMoreThanRecommended"
            @change="onSwitchFilterChanged($event, 'showMoreThanRecommended')"
            switch
          >
            {{ $t("current-loads.more-than-recommended-switch") }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-container>
    <b-pagination
      :value="$store.state.currentLoads.pagination.currentPage"
      :total-rows="$store.state.currentLoads.pagination.totalCount"
      :per-page="$store.state.currentLoads.pagination.limit"
      @input="$store.dispatch('currentLoads/setCurrentPage', $event)"
    ></b-pagination>
    <b-table
      show-empty
      responsive
      no-local-sorting
      :items="items"
      :fields="fields"
      :empty-text="$t('current-loads.table.empty-text')"
      :busy="$store.state.currentLoads.isLoading"
      @sort-changed="onSortChanged"
    >
      <template v-slot:top-row>
        <td>
          <b-input
            :value="filter.registrationNumber"
            @input="onStringFilterChanged($event, 'registrationNumber')"
          ></b-input>
        </td>
        <td>
          <b-input :value="filter.brand" @input="onStringFilterChanged($event, 'brand')"></b-input>
        </td>
        <td>
          <b-input :value="filter.model" @input="onStringFilterChanged($event, 'model')"></b-input>
        </td>
        <td>
          <b-input :value="filter.category" @input="onStringFilterChanged($event, 'category')"></b-input>
        </td>
        <td>
          <b-input :value="filter.type" @input="onStringFilterChanged($event, 'type')"></b-input>
        </td>
        <td>
          <b-input :value="filter.template" @input="onStringFilterChanged($event, 'template')"></b-input>
        </td>
        <td>
          <b-input :value="filter.loadedBy" @input="onStringFilterChanged($event, 'loadedBy')"></b-input>
        </td>
        <td>
          <b-form-datepicker
            :value="filter.loadedAt"
            @input="onLoadedAtChanged"
            :locale="$i18n.locale"
            placeholder=""
            boundary="window"
          ></b-form-datepicker>
        </td>
      </template>
      <template v-slot:cell(category)="row">{{ row.value.title[$i18n.locale] }}</template>
      <template v-slot:cell(type)="row">{{ row.value.title[$i18n.locale] }}</template>
      <template v-slot:cell(loadedBy)="row">
        <span v-if="row.value">{{ row.value.userName }}</span>
      </template>
      <template v-slot:cell(loadedAt)="row">
        <span v-if="row.value">{{ $d(Date.parse(row.value), "log") }}</span>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button size="sm" @click="onLoadedItemsClick(row.item.id)" class="mr-1">
          {{ $t("current-loads.loaded-items") }}
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  computed: {
    filter() {
      return this.$store.state.currentLoads.filter;
    },
    filterCopy() {
      // modifying state directly is a bad practice - we take filter copy for update methods
      return { ...this.filter };
    },
    items() {
      return this.$store.state.currentLoads.vehicles;
    },
    fields() {
      return [
        {
          key: "registrationNumber",
          label: this.$t("current-loads.table.registrationNumber"),
          sortable: true
        },
        { key: "brand", label: this.$t("current-loads.table.brand"), sortable: true },
        { key: "model", label: this.$t("current-loads.table.model"), sortable: true },
        { key: "category", label: this.$t("current-loads.table.category"), sortable: true },
        { key: "type", label: this.$t("current-loads.table.type"), sortable: true },
        { key: "templateTitle", label: this.$t("current-loads.table.template"), sortable: true },
        { key: "loadedBy", label: this.$t("current-loads.table.loaded-by"), sortable: true },
        { key: "loadedAt", label: this.$t("current-loads.table.loaded-at"), sortable: true },
        { key: "actions", label: "" }
      ];
    }
  },
  methods: {
    setFilter(newFilter) {
      this.$store
        .dispatch("currentLoads/setFilter", newFilter)
        .catch(() => alert(this.$t("current-loads.error.when-loading-search-result")));
    },
    onSwitchFilterChanged(evt, field) {
      let filterCopy = this.filterCopy;
      filterCopy[field] = evt;
      this.setFilter(filterCopy);
    },
    onStringFilterChanged(evt, field) {
      let filterCopy = this.filterCopy;
      filterCopy[field] = evt.trim();
      this.setFilter(filterCopy);
    },
    onLoadedAtChanged(evt) {
      let filterCopy = this.filterCopy;
      filterCopy.loadedAt = evt;
      this.setFilter(filterCopy);
    },
    onLoadedItemsClick(id) {
      this.$router.push({ name: "current-load", params: { vehicleId: id } });
    },
    onSortChanged(ctx) {
      this.$store.dispatch("currentLoads/setSortParams", {
        sortBy: ctx.sortBy,
        isSortDesc: ctx.sortDesc
      });
    }
  }
};
</script>
