<template>
  <div>
    <b-overlay :show="this.$store.state.goodsGroup.isLoading">
      <b-row class="mb-2">
        <b-col>
          <v-select
            multiple
            :value="selectedGoodsGroups"
            @input="onSelectedGoodsGroupsChanged"
            :options="goodsGroupsOptions"
            :getOptionLabel="x => x.title[$i18n.locale]"
            :placeholder="$t('current-loads.selectGroup')"
          >
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-col>
      </b-row>
    </b-overlay>
    <b-overlay :show="this.$store.state.goods.isLoading">
      <b-row class="mb-2">
        <b-col>
          <v-select
            multiple
            :value="selectedGoods"
            @input="onSelectedGoodsChanged"
            :options="goodsOptions"
            :getOptionLabel="x => x.title[$i18n.locale]"
            :placeholder="$t('current-loads.selectProduct')"
          >
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
export default {
  computed: {
    allGroups() {
      return this.$store.state.goodsGroup.goodsGroups;
    },
    allGoods() {
      return this.$store.state.goods.goods;
    },
    selectedGoodsGroups() {
      return this.allGroups.filter(x => this.$store.state.currentLoads.selectedGoodsGroupsIds.includes(x.id));
    },
    goodsGroupsOptions() {
      return this.allGroups.filter(x => !this.$store.state.currentLoads.selectedGoodsGroupsIds.includes(x.id));
    },
    selectedGoods() {
      return this.allGoods.filter(x => this.$store.state.currentLoads.selectedGoodsIds.includes(x.id));
    },
    goodsOptions() {
      let result = this.$store.state.goods.goods.filter(
        x => !this.$store.state.currentLoads.selectedGoodsIds.includes(x.id)
      );
      if (this.$store.state.currentLoads.selectedGoodsGroupsIds.length > 0) {
        result = result.filter(x =>
          x.groupIds.some(y => this.$store.state.currentLoads.selectedGoodsGroupsIds.includes(y))
        );
      }
      return result;
    }
  },
  methods: {
    onSelectedGoodsGroupsChanged(evt) {
      this.$store.commit(
        "currentLoads/setSelectedGoodsGroupsIds",
        evt.map(x => x.id)
      );
    },
    onSelectedGoodsChanged(evt) {
      this.$store
        .dispatch(
          "currentLoads/setSelectedGoods",
          evt.map(x => x.id)
        )
        .catch(() => alert(this.$t("current-loads.error.when-loading-search-result")));
    }
  }
};
</script>
